<template>
  <v-col
    class="image-wrapper mt-sm-2 d-flex justify-center justify-sm-start"
    :class="{'image-wrapper__empty': !imageUrl(attachment)}"
    cols="12"
    md="2">
    <div class="relative">
      <img
        v-if="imageUrl(attachment)"
        v-lazy="imageUrl(attachment)">
      <div class="verified-mark">
        <AppVerifiedMark
          :is-verified="isVerified"
          :child-verified-ws="item.childVerified"
          :verified-page-id="item.verifiedPageId"
          :verified-page-name="item.verifiedPageName" />
      </div>
    </div>
  </v-col>
</template>
<script>
import AppVerifiedMark from '@/components/App/AppVerifiedMark';
import { parseStorageKey } from '@/utils';
import { Storage } from 'aws-amplify';
export default {
  name: 'ProductThumbnail',
  components: {
    AppVerifiedMark,
  },
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    item: {
      type: Object,
      default: () => {},
    },
    attachment: {
      type: [Array, Object],
      default: () => [],
    },
  },
  computed: {
    products: {
      get() {
        return this.items;
      },
      set(val) {
        this.$emit('update:items', val);
      },
    },
    isVerified() {
      return this.item?.isVerified;
    },
  },
  watch: {
    products: {
      handler() {
        this.getImageFromStorage();
      },
      immediate: true,
    },
  },
  methods: {
    imageUrl(attachment) {
      return attachment ? this.smallThumb(attachment[0])?.url : null;
    },
    smallThumb(attachment) {
      const thumbnails = attachment?.thumbnails;
      return thumbnails?.medium || thumbnails?.large;
    },
    async getImageFromStorage() {
      this.products = await Promise.all(this.products.map(async (v)=> {
        try {
          const filtered = v.attachment?.filter(item => item);
          const attachment = filtered?.length && filtered[0];
          const smallThumb = attachment ? this.smallThumb(attachment) : {
          };
          const { url, key: thumbKey } = smallThumb;
          if (!url && thumbKey) {
            const { identityId, key } = parseStorageKey(thumbKey);
            smallThumb.url = await Storage.get(
              key, {
                level: 'protected',
                identityId,
              }
            );
          }
        } catch (err) {
          console.log('getImageFromStorage err', err);
        }
        return v;
      }));
    },
  },
};
</script>
<style scoped lang="scss">
  .image-wrapper {
    position: relative;
    &__empty {
      background: var(--v-lightGrey-base);
      opacity: 0.2;
    }
    img {
      max-width: 100px;
      max-height: 100px;
    }
  }
  .verified-mark {
    position: absolute;
    z-index: 4;
    top: -6%;
    right: -10%;
  }
</style>
